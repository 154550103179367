import { Row, Spin } from "antd";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AppLayout } from "./components/app-layout/app-layout.component";
import { GlobalModals } from "./components/global-modals/global-modals.component";
import { ProtectedRoute } from "./components/protected-route/protected-route.component";
import "./index.css";
import "./tailwind.css";
import "./app.css";
import { AppRoutes } from "./pages/app-routes.component";

const Login = lazy(() => import("./pages/login/login.page"));
const OrgWhatsapp = lazy(() => import("./pages/whatsapp/org-whatsapp.page"));
const ChatSelector = lazy(
  () => import("./pages/chat-selector/chat-selector.page")
);
const Chat = lazy(() => import("./pages/chat/chat.page"));
const ChatSettings = lazy(
  () => import("./pages/chat-settings/chat-settings.page")
);
const WPChatPage = lazy(() => import("./pages/wp-chat/wp-chat.page"));

const App = () => {
  return (
    <Suspense
      fallback={
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: "100vh",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={true} />
        </Row>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login-admin" element={<Login />} />
        <Route
          path="/whatsapp/:orgId"
          element={
            <ProtectedRoute>
              <OrgWhatsapp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <ChatSelector />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat/:orgId"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat-settings/:orgId"
          element={
            <ProtectedRoute>
              <ChatSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <>
              <ProtectedRoute>
                <AppLayout>
                  <AppRoutes />
                </AppLayout>
              </ProtectedRoute>
              <GlobalModals />
            </>
          }
        />
        <Route
          path="/chat-new"
          element={
            <ProtectedRoute>
              <WPChatPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;
